import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CircularProgress } from '@mui/material';
import useCronSettings from './service';
import CronJobToolbar from './Toolbar';

function CronJobsSovrn() {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowToDelete, setRowToDelete] = useState([]);
  const { getCronSettings, getTrafficSources } = useCronSettings();
  const [trafficSources, setTrafficSources] = useState([]);

  const traffic = () => {
    const apiCall = getTrafficSources();

    (async function () {
      try {
        setLoading(true);
        const res = await apiCall.call();
        setTrafficSources(res.trafficSources);
        setLoading(res.trafficSources.length > 0 ? false : true);
      } catch (e) {
        console.error(e);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return traffic();
  }, []);

  const columnDefs = [
    {
      headerName: 'Report Tag',
      field: 'reportTag',
      sortable: true,
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: 'Traffic Name',
      field: 'trafficId',
      sortable: true,
      filter: true,
      valueGetter: (params) => {
        const trafficSource = trafficSources.find(
          (source) => source._id === params.data.trafficId
        );
        return trafficSource ? trafficSource.name : params.data.trafficId;
      },
    },
    {
      headerName: 'Filter Tag',
      field: 'filterTag',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Account',
      field: 'account',
      sortable: true,
      filter: true,
    },
    { headerName: 'Endpoint', field: 'endpoint', sortable: true, filter: true },
  ];

  const init = () => {
    const apiCall = getCronSettings();

    (async function () {
      try {
        setLoading(true);
        const res = await apiCall.call();
        setRowData(res);
        setLoading(res.length > 0 ? false : true);
      } catch (e) {
        console.error(e);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  const onAddDone = async (newCronJob) => {
    setRowData((prevData) => [...prevData, newCronJob]);
  };

  const onEditDone = async (editedCronJob) => {
    try {
      setRowData((prevData) =>
        prevData.map((item) =>
          item._id === editedCronJob._id ? editedCronJob : item
        )
      );
    } catch (error) {
      console.error('Error editing cron job:', error);
    }
  };

  const onDeleteDone = async (ids) => {
    setRowData((prevData) =>
      prevData.filter((item) => !ids.includes(item._id))
    );
  };


  return (
    <Card>
      <CronJobToolbar
        selectedRow={selectedRow}
        onAddDone={onAddDone}
        onEditDone={onEditDone}
        onDeleteDone={onDeleteDone}
        rowToDelete={rowToDelete}
        multipleSelected={rowToDelete.length > 1}
      />
      <Box
        sx={{
          width: '100%',
          height: '1000px',
        }}
        className="ag-theme-alpine"
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <AgGridReact
            rowData={rowData} // Data to display in the table
            columnDefs={columnDefs} // Column definitions
            pagination={true} // Enable pagination
            paginationPageSize={20} // Set page size
            headerHeight={50} // Set header height
            rowSelection="multiple" // Enable row selection
            onRowSelected={({ api }) => {
              const selectedNode = api.getSelectedNodes()[0];
              setSelectedRow(selectedNode ? selectedNode.data : null);
            }}
            onSelectionChanged={({ api }) => {
              const selectedNodes = api.getSelectedNodes();
              setRowToDelete(selectedNodes.map((node) => node.data._id));
            }}
            defaultColDef={{
              flex: 1, // Make columns take up all available space
              minWidth: 100, // Set a minimum width for columns
            }}
          />
        )}
      </Box>
    </Card>
  );
}

export default CronJobsSovrn;