import {
  Typography,
  TextField,
  Button,
  MenuItem,
  Box,
  Alert,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { PostbackContext } from '../context';
import { useContext, useState, useEffect } from 'react';

import SimpleMenu from '../../../components/SimpleMenu';
import SimpleModal from '../../../components/SimpleModal';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const CONDITIONS = ['None', 'Equals', 'Does not contain', 'Contains'];

export default function AddPostback({ open, handleClose, onSubmit }) {
  const postbackContext = useContext(PostbackContext);

  const [values, setValues] = useState({
    destinationId: '',
    destinationName: '',
    destinationUrl: [''],
    trafficSourceId: [''],
    clickIdParam: '',
    ignoreDuplicateClickId: false, // Initialize checkbox value
  });
  const [conditions, setConditions] = useState([
    { type: 0, parameter: '', value: '' },
  ]);

  useEffect(() => {
    if (!open) {
      setValues({
        destinationId: '',
        destinationName: '',
        destinationUrl: [''],
        trafficSourceId: [''],
        clickIdParam: '',
        ignoreDuplicateClickId: false, // Reset checkbox value
      });
      setConditions([{ type: 0, parameter: '', value: '' }]);
    }
  }, [open]);

  const resetForm = () => {
    setValues({
      destinationId: '',
      destinationName: '',
      destinationUrl: [''],
      trafficSourceId: [''],
      clickIdParam: '',
      ignoreDuplicateClickId: false,
    });
    setConditions([{ type: 0, parameter: '', value: '' }]);
  };

  const onChange = (e, index) => {
    const { name, value, checked, type } = e.target;

    if (name === 'destinationUrl') {
      const updatedUrls = [...values.destinationUrl];
      updatedUrls[index] = value;
      setValues({ ...values, destinationUrl: updatedUrls });
    } else if (name === 'trafficSourceId') {
      const updatedIds = [...values.trafficSourceId];
      updatedIds[index] = value;
      setValues({ ...values, trafficSourceId: updatedIds });
    } else if (type === 'checkbox') {
      setValues({ ...values, [name]: checked }); // Handle checkbox
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const addUrlDestinationPair = () => {
    setValues({
      ...values,
      destinationUrl: [...values.destinationUrl, ''],
      trafficSourceId: [...values.trafficSourceId, ''],
    });
  };

  const handleValueChange = (index, key, newValue) => {
    const updatedConditions = [...conditions];

    if (key === 'type' && newValue === 0) {
      updatedConditions[index]['parameter'] = '';
      updatedConditions[index]['value'] = '';
    }

    updatedConditions[index][key] = newValue;
    setConditions(updatedConditions);
  };

  const addCondition = () => {
    setConditions([...conditions, { type: 0, parameter: '', value: '' }]);
  };

  const removeCondition = (index) => {
    const updatedConditions = [...conditions];
    updatedConditions.splice(index, 1);
    setConditions(updatedConditions);
  };

  const removePair = (index) => {
    const newUrls = values.destinationUrl.filter((_, i) => i !== index);
    const newTrafficIds = values.trafficSourceId.filter((_, i) => i !== index);

    setValues({
      ...values,
      destinationUrl: newUrls,
      trafficSourceId: newTrafficIds,
    });
  };

  const preventScrolling = (e) => {
    e.target.addEventListener(
      'wheel',
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  };

  const handleSubmit = async () => {
    try {
      await onSubmit({
        ...values,
        conditionRegex: conditions.map(({ type, parameter, value }) => ({
          type,
          parameter,
          value,
        })),
      });
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  const { traffic } = postbackContext;

  return (
    <SimpleModal
      open={open}
      handleClose={handleClose}
      containerStyle={{ width: '40%' }}
    >
      <Typography variant="h6">Add Postback</Typography>
      <TextField
        name="destinationId"
        label="Destination Id"
        onChange={onChange}
        value={values.destinationId}
        fullWidth
      />
      <TextField
        name="destinationName"
        label="Destination Name"
        onChange={onChange}
        value={values.destinationName}
        fullWidth
      />
      {values.destinationUrl.map((destinationUrl, index) => (
        <Box key={index} display="flex" alignItems="center" gap={2}>
          <TextField
            name="destinationUrl"
            label="Destination Url"
            onChange={(e) => onChange(e, index)}
            value={destinationUrl}
            fullWidth
          />
          <TextField
            select
            name="trafficSourceId"
            label="Traffic source"
            onChange={(e) => onChange(e, index)}
            value={values.trafficSourceId[index]}
            fullWidth
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">None</MenuItem>
            {traffic.map((item) => (
              <MenuItem value={item._id} key={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>

          <IconButton
            onClick={() => removePair(index)}
            disabled={values.destinationUrl == 1}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Box>
        <Button variant="outlined" onClick={addUrlDestinationPair}>
          Add new destination
        </Button>
      </Box>
      <TextField
        name="clickIdParam"
        label="Parameter For Click Id"
        onChange={onChange}
        value={values.clickIdParam}
        fullWidth
        margin="normal"
      />
      <TextField
        name="deductionRate"
        label="Deduction Rate (%)"
        onChange={(e) => {
          const parseInput = parseInt(e.target.value);
          e.target.value =
            parseInput > 0 && parseInput <= 100
              ? (e.target.value = parseInput)
              : (e.target.value = '');
          onChange(e);
        }}
        onFocus={(e) => preventScrolling(e)}
        type="number"
        value={values.deductionRate}
        fullWidth
        margin="normal"
      />
      <TextField
        name="commissionTag"
        label="Parameter For Commission"
        onChange={onChange}
        value={values.commissionTag}
        fullWidth
        margin="normal"
      />
      {/* Add Checkbox below clickIdParam */}
      <FormControlLabel
        control={
          <Checkbox
            name="ignoreDuplicateClickId"
            checked={values.ignoreDuplicateClickId}
            onChange={onChange}
          />
        }
        label="Ignore Duplicate Click Id"
      />

      <Typography variant="title">Parameter Optional Conditions</Typography>
      <Alert severity="info">
        CASE-SENSITIVE. These are checked before a postback request is sent. You
        can add up to 3 parameter optional conditions.
      </Alert>
      {conditions.map((condition, index) => (
        <Box key={index} display="flex" alignItems="center" gap={2}>
          <SimpleMenu
            item={condition}
            menuItems={CONDITIONS}
            index={index}
            handleValueChange={handleValueChange}
          />
          <TextField
            label="Parameter"
            value={condition.parameter}
            onChange={(e) =>
              handleValueChange(index, 'parameter', e.target.value)
            }
            disabled={condition.type === 0}
          />
          <TextField
            label="Value"
            value={condition.value}
            onChange={(e) => handleValueChange(index, 'value', e.target.value)}
            disabled={condition.type === 0}
          />
          {conditions.length > 1 && (
            <IconButton onClick={() => removeCondition(index)}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      {conditions.length < 3 && (
        <Box>
          <Button variant="outlined" onClick={addCondition}>
            Add new condition
          </Button>
        </Box>
      )}
      <Button variant="contained" onClick={handleSubmit}>
        Add
      </Button>
    </SimpleModal>
  );
}
