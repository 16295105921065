import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

function DeleteCronJob({ open, handleClose, onSubmit, initialData }) {
  const handleDelete = () => {
    onSubmit(initialData, handleClose);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>Delete Cron Job</DialogTitle>
      <DialogContent>
        Are you sure you want to delete the selected cron job(s)?
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={handleDelete} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCronJob;
