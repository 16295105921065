import { create } from "lodash";
import useApi from "../../hooks/useApi";

const API_URL = '/api/logs';

export default function useLogs() {
  const { api:apiClient, createApiCall } = useApi();

  const getLogs = createApiCall(async ({ signal }) => {
    const {
      data: { logs }
    } = await apiClient.get(API_URL, { signal });

    return logs;
  });

  const getLogsByDate = createApiCall(async ({ signal }, startDate, endDate) => {
    const {
      data: { logs }
    } = await apiClient.get(`${API_URL}/by-date`, {
      params: { startDate, endDate },
      signal
    });
  
    return logs;
  });

  return { getLogs, getLogsByDate };
}