import React, { useState, useContext } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  CssBaseline,
} from '@mui/material';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AuthContext } from './context/authContext';
import InboxApp from './components/Inbox';

import {
  Dashboard as DashboardIcon,
  Group as GroupIcon,
  PostAdd as PostAddIcon,
  Domain as DomainIcon,
  Traffic as TrafficIcon,
  Store as StoreIcon,
  Person as AffiliateIcon,
  Category as ProductIcon,
  Public as SovrnIcon,
  Assessment as ReportIcon,
  Security as SecurityIcon,
  Assignment as RequestIcon,
  Logout,
  MenuBook,
} from '@mui/icons-material';

import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './index.css';

const menuItems = [
  { label: 'Offer Group', path: '/offer-group', icon: <DashboardIcon /> },
  { label: 'Logs', path: '/logs', icon: <GroupIcon /> },
  { label: 'Postback', path: '/postback', icon: <PostAddIcon /> },
  { label: 'Offers', path: '/offers', icon: <DashboardIcon /> },
  { label: 'Domains', path: '/domains', icon: <DomainIcon /> },
  { label: 'Publisher', path: '/publishers', icon: <MenuBook /> },
  { label: 'Traffic Sources', path: '/traffic', icon: <TrafficIcon /> },
  { label: 'Merchants', path: '/merchants', icon: <StoreIcon /> },
  { label: 'Affiliates', path: '/affiliates', icon: <AffiliateIcon /> },
  { label: 'Products', path: '/products', icon: <ProductIcon /> },
  { label: 'Sovrn', path: '/sovrn', icon: <SovrnIcon /> },
  { label: 'Hyprmotion', path: '/hp-reports', icon: <ReportIcon /> },
  { label: 'Iron Dome', path: '/iron-dome', icon: <SecurityIcon /> },
  { label: 'Request', path: '/request', icon: <RequestIcon /> },
  { label: 'Cron Jobs', path: '/cron-jobs', icon: <AccessAlarmIcon /> },
];

function App() {
  const { logout, isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const noLayoutRoutes = ['/login', '/404'];
  const showLayout = !noLayoutRoutes.includes(location.pathname);

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  return (
    <>
      <CssBaseline />

      {showLayout && (
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Admin Panel
            </Typography>
            {isAuthenticated && (
              <IconButton
                sx={{
                  bgcolor: 'white',
                  pl: 0.6,
                  pr: 0.9,
                  pb: 1,
                  pt: 0.5,
                  '&:hover': { bgcolor: 'white' },
                }}
              >
                <InboxApp />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      )}

      {showLayout && (
        <Drawer
          variant="permanent"
          open={isDrawerOpen}
          sx={{
            width: isDrawerOpen ? 240 : 60,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: isDrawerOpen ? 240 : 60,
              boxSizing: 'border-box',
              transition: 'width 0.3s',
              overflow: 'hidden',
            },
          }}
        >
          <Toolbar />
          <List>
            {menuItems.map((item) => (
              <ListItem
                key={item.path}
                button
                component={NavLink}
                to={item.path}
                sx={{ '&.active': { backgroundColor: '#f0f0f0' } }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {isDrawerOpen && (
                  <ListItemText
                    primary={item.label}
                    sx={{ '& .MuiTypography-root': { fontSize: '0.85rem' } }}
                  />
                )}
              </ListItem>
            ))}
          </List>

          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              width: isDrawerOpen ? 240 : 60,
              pl: isDrawerOpen ? 2 : 1,
              pb: isDrawerOpen ? 2 : 1,
              pr: isDrawerOpen ? 2 : 0,
            }}
          >
            {isDrawerOpen ? (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={logout}
              >
                Log out
              </Button>
            ) : (
              <IconButton color="primary" onClick={logout}>
                <Logout />
              </IconButton>
            )}
          </Box>
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: showLayout ? (isDrawerOpen ? '240px' : '60px') : '0px',
          marginTop: showLayout ? '64px' : '0px',
          transition: 'margin-left 0.3s',
        }}
      >
        <Outlet />
      </Box>
    </>
  );
}

export default App;
