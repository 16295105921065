import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Divider,
  Box,
  Autocomplete,
} from '@mui/material';
import useCronSettings from '../service.js';

function AddCronJob({ open, handleClose, onSubmit }) {
  const initialFormData = {
    reportTag: '',
    trafficId: '',
    filterTag: '',
    account: '',
    endpoint: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [trafficSources, setTrafficSources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { getTrafficSources } = useCronSettings();

  const init = () => {
    const apiCall = getTrafficSources();
    
    (async function () {
      try {
        setLoading(true);
        const res = await apiCall.call();
        setTrafficSources(res.trafficSources);
        setLoading(res.trafficSources.length > 0 ? false : true);
      } catch (e) {
      } finally {
        // setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  const selectedTraffic = trafficSources.find(
    (t) => t._id === formData.trafficId
  );

  const selectedTrafficOptions = selectedTraffic
    ? selectedTraffic.parameterOptions?.map((p) => (
        <MenuItem key={p.tag} value={p.tag}>
          {p.tag}
        </MenuItem>
      ))
    : [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleAutocompleteChange = (event, value) => {
    setFormData({ ...formData, trafficId: value ? value._id : '' });
    setErrors({ ...errors, trafficId: '' });
  };

  const handleSubmit = () => {
    const newErrors = {};
    if (!formData.reportTag) newErrors.reportTag = 'Report Tag is required';
    if (!formData.trafficId) newErrors.trafficId = 'Traffic Source is required';
    if (!formData.account) newErrors.account = 'Account is required';
    if (!formData.endpoint) newErrors.endpoint = 'Endpoint is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    onSubmit(formData, handleClose);
    setFormData(initialFormData);
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    handleClose(false);
  };

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth>
      <DialogTitle>Add Cron Job</DialogTitle>
      <Divider sx={{ mt: 1 }} />
      <DialogContent>
        <Box gap={2} display={'flex'} flexDirection={'column'}>
          <TextField
            name="account"
            label="Account"
            select
            onChange={handleChange}
            value={formData.account}
            fullWidth
            error={!!errors.account}
            helperText={errors.account}
          >
            <MenuItem value="sovrn">Sovrn (Sovrn CNX account)</MenuItem>
            <MenuItem value="sean">Sean (Sean@ CNX account)</MenuItem>
          </TextField>
          <TextField
            fullWidth
            label="Report Tag"
            name="reportTag"
            value={formData.reportTag}
            onChange={handleChange}
            error={!!errors.reportTag}
            helperText={errors.reportTag}
          />
          <Autocomplete
            options={trafficSources}
            getOptionLabel={(option) => option.name}
            onChange={handleAutocompleteChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Traffic Source"
                error={!!errors.trafficId}
                helperText={errors.trafficId}
              />
            )}
          />
          <TextField
            name="filterTag"
            label="Prefix"
            select
            disabled={!formData.trafficId}
            value={formData.filterTag}
            onChange={handleChange}
            fullWidth
          >
            {selectedTrafficOptions}
          </TextField>
          <TextField
            fullWidth
            label="Endpoint"
            name="endpoint"
            value={formData.endpoint}
            onChange={handleChange}
            select
            error={!!errors.endpoint}
            helperText={errors.endpoint}
          >
            <MenuItem value="xmptrk">XMPTRK</MenuItem>
            <MenuItem value="eagleview">Eagleview</MenuItem>
          </TextField>
        </Box>
      </DialogContent>
      <Divider sx={{ mt: 2 }} />
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCronJob;