import React, { useState, useEffect } from 'react';
import { useCSVReader } from 'react-papaparse';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import Autocomplete from '@mui/material/Autocomplete';

function ImportOfferDialog({ open, handleClose, onUpload, postbacks }) {
  const { CSVReader } = useCSVReader();
  const [destinationId, setDestinationId] = useState('');
  const [destinationIds, setDestinationIds] = useState([]);
  const [fileName, setFileName] = useState('');
  const [csvData, setCsvData] = useState([]);
  const [isCsv, setIsCsv] = useState(false);

  useEffect(() => {
    if (postbacks) {
      const uniqueDestinationIds = [
        ...new Set(postbacks.map((postback) => postback.destinationId)),
      ];
      setDestinationIds(uniqueDestinationIds);
      if (uniqueDestinationIds.length > 0) {
        setDestinationId(uniqueDestinationIds[0]);
      }
    }
  }, [postbacks]);

  const handleUploadAccepted = (data, file) => {
    if (file.type !== 'text/csv') {
      setFileName('Only CSV files are allowed.');
      setIsCsv(false);
      setCsvData([]);
      return;
    }
    setIsCsv(true);
    setFileName(file.name);
    setCsvData(data.data);
  };

  const handleDialogClose = () => {
    setFileName('');
    setCsvData([]);
    setDestinationId(destinationIds.length > 0 ? destinationIds[0] : '');
    handleClose();
  };

  const handleSubmit = () => {
    const headers = csvData[0];
    const formattedData = [];
  
    for (let i = 1; i < csvData.length; i++) {
      const row = csvData[i];
      if (row.every((cell) => cell === '')) {
        break;
      }
  
      const dateValue = row[headers.indexOf('Conversion Date')];
      const formattedDate = dateValue;
  
      const postback = postbacks.find(
        (pb) => pb.destinationId === destinationId
      );
      let clickIdParam = postback ? postback.clickIdParam : 'clickid';
      if (clickIdParam === '') {
        clickIdParam = 'clickid';
      }
  
      const formattedRow = {
        clickid: row[headers.indexOf('Generated Value')],
        payout: row[headers.indexOf('Payout')],
        conversionDate: formattedDate,
        id: destinationId,
        clickIdParam: clickIdParam,
      };
  
      if (
        formattedRow.clickid &&
        formattedRow.payout &&
        formattedRow.conversionDate
      ) {
        formattedData.push(formattedRow);
      }
    }
  
    onUpload(formattedData, isCsv, fileName);
    handleDialogClose();
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="xs" fullWidth>
      <DialogTitle>Upload CSV</DialogTitle>
      <DialogContent>
        <CSVReader onUploadAccepted={handleUploadAccepted} accept=".csv">
          {({ getRootProps }) => (
            <Box>
              <Button
                {...getRootProps()}
                size="large"
                startIcon={<UploadIcon />}
                sx={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                Upload CSV
              </Button>
              {fileName && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginTop: '10px' }}
                >
                  Uploaded file: {fileName}
                </Typography>
              )}
              <Autocomplete
                options={destinationIds}
                value={destinationId}
                onChange={(event, newValue) => setDestinationId(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Destination ID"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </Box>
          )}
        </CSVReader>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImportOfferDialog;
