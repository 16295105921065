import React, { useState, useMemo, useEffect, memo } from 'react';
import { Card, Box, Chip, FormControlLabel, Switch } from '@mui/material';
import useTraffic from '../useTraffic';
import TrafficToolbar from './Toolbar';
import { useNavigate, Link } from 'react-router-dom';

import ConfirmModal from '../../../components/ConfirmModal';
import TooltipIconButton from '../../../components/TooltipIconButton';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import SettingsIcon from '@mui/icons-material/Settings';
import DynamicTable from '../../../components/DynamicTable';
import { DisabledByDefault } from '@mui/icons-material';

import QuickFilter from '../../../components/QuickFilter';
import { update } from 'lodash';

function TrafficTable() {
  const [traffic, setTraffic] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quickFilter, setQuickFilter] = useState('');
  const { getTraffic, deleteTraffic, editTraffic } = useTraffic();
  const [showArchive, setShowArchive] = useState(false);
  const [finalTraffic, setFinalTraffic] = useState([]);

  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState('');

  const init = () => {
    setLoading(true);
    const apiCall = getTraffic();

    (async function () {
      try {
        const traffic = await apiCall.call();
        setTraffic(traffic);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  const renderCell = (type, data) => {
    switch (type) {
      case 'name':
        return <Link to={data._id}>{data.name}</Link>;

      case 'parameterOptions':
        const parameterOptions = data.parameterOptions;

        const hasNoParameters = parameterOptions.length < 1;

        if (hasNoParameters) return <Chip label="No parameters" />;

        return data.parameterOptions.map((item) => {
          return <Chip label={item.tag} sx={{ mr: 1 }} />;
        });

        case 'archive':
          return (
            <Chip
              label={`${data.archive}`}
              color={data.archive ? 'success' : 'error'}
            />
          );
          
      case 'actions':
        const handleArchiveToggle = () => {
          const newArchiveStatus = !data.archive;
          updateTrafficArchiveStatus(data._id, newArchiveStatus);
        }
        return (
          <Box>
            <TooltipIconButton
              onClick={() => navigate(data._id)}
              title="View"
              icon={<SettingsIcon />}
            />
            <TooltipIconButton
              onClick={() => navigate(`${data._id}/block`)}
              title="Block rules"
              icon={<DisabledByDefault />}
            />
            <TooltipIconButton
              icon={data.archive ? <UnarchiveIcon /> : <ArchiveIcon />}
              title={data.archive ? 'Unarchive' : 'Archive'}
              onClick={handleArchiveToggle}
              size="small"
            />
          </Box>
        );
    }
  };

  const updateTrafficArchiveStatus = async(id, newArchiveStatus) => {

    const updatedTraffic = traffic.map((item) => {
      if (item._id === id) {
        const updatedTraffic = { ...item, archive: newArchiveStatus };
        editTraffic().call(id, updatedTraffic);
        return updatedTraffic;
      }
      return item;
    });
    setTraffic(updatedTraffic);
  }

  const onAddTrafficDone = (newTraffic) => {
    setTraffic([...traffic, newTraffic]);
  };

  const onEditTrafficDone = (newTraffic) => {
    const newTraffics = traffic.map((item) => {
      if (item._id === selectedRows[0]) return newTraffic;
      return item;
    });

    setTraffic(newTraffics);
  };

  const onDeleteTrafficDone = (id) => {
    const newTraffic = traffic.filter((item) => !id.includes(item._id));
    setTraffic(newTraffic);
  };

  const onBulkArchiveDone = (updatedRows) => {
    const newTraffic = traffic.map((item) => {
      const updatedTraffic = updatedRows.find((updated) => updated._id === item._id);
      return updatedTraffic ? { ...item, archive: updatedTraffic.archive } : item;
    });
    setTraffic(newTraffic);
  };

  const handleDelete = async (sourceId) => {
    try {
      setLoading(true);
      await deleteTraffic().call(sourceId);
      const newTraffic = traffic.filter((item) => item._id !== sourceId);

      setTraffic(newTraffic);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return init();
  }, []);

  const cols = generateTableCols(handleDelete, navigate);

  const selectedRowData = useMemo(() => {
    if (selectedRows.length > 1) {
      return undefined;
    } else {
      return traffic.find((item) => item._id === selectedRows[0]);
    }
  }, [selectedRows, traffic]);

  useEffect(() => {
    setSelectedRows([]);
  }, [traffic]);

  const filteredTraffic = useMemo(() => {
    return traffic.filter((item) => {
      const lowercasedFilter = quickFilter.toLowerCase();
  
      const nameMatches = item.name.toLowerCase().includes(lowercasedFilter);
  
      const parametersMatch = item.parameterOptions.some((param) =>
        param.tag.toLowerCase().includes(lowercasedFilter)
      );
  
      return nameMatches || parametersMatch;
    });
  }, [traffic, quickFilter]);
  
  useEffect(() => {
    let finalTraffic;
    if (showArchive) {
        finalTraffic = filteredTraffic;
    } else {
        finalTraffic = filteredTraffic.filter(
            (item) => item.archive === false
        );
    }
    setFinalTraffic(finalTraffic);
  }, [filteredTraffic, showArchive]);

  return (
    <Box mt={4}>
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <QuickFilter
            quickFilter={quickFilter}
            setQuickFilter={setQuickFilter}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showArchive}
                onChange={(e) => setShowArchive(e.target.checked)}
                name="showArchived"
                color="primary"
              />
            }
            label="Show Archived"
          />
        </Box>
        <TrafficToolbar
          selectedRow={selectedRowData}
          onAddTrafficDone={onAddTrafficDone}
          onEditTrafficDone={onEditTrafficDone}
          onDeleteTrafficDone={onDeleteTrafficDone}
          onBulkArchiveDone={onBulkArchiveDone}
          rowToDelete={selectedRows}
          rowToArchive={selectedRows}
          showArchive={showArchive}
          currentData={traffic}
        />
        <DynamicTable
          columns={cols}
          filteredData={finalTraffic}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          renderCell={renderCell}
        />
      </Card>
    </Box>
  );
}

const generateTableCols = (onDelete, onView) => {
  const tableCols = [
    {
      name: 'Name',
      field: 'name',
      flex: 1,
      filter: false,
    },
    {
      name: 'Parameters',
      field: 'parameterOptions',
      flex: 2,
      filter: false,
    },
    {
      name: 'Archive',
      field: 'archive',
      flex: 2,
      filter: false,
    },
    {
      name: 'Actions',
      field: 'actions',
      flex: 1,
      filter: false,
    },
  ];

  return tableCols;
};
export default memo(TrafficTable);
