import { create } from 'lodash';
import useApi from '../../hooks/useApi';

const API_URL = '/api/traffic';
const REDIRECT_URL = '/api/redirect';
const API_REDIRECT_URL = '/api/traffic/trafficRedirects';

function useTraffic() {
  const { api: apiClient, createApiCall } = useApi();

  const getTraffic = createApiCall(async ({ signal }) => {
    const {
      data: { trafficSources },
    } = await apiClient.get(API_URL, { signal });

    return trafficSources;
  });

  const getSingleTraffic = createApiCall(async ({ signal }, id) => {
    const {
      data: { trafficSources },
    } = await apiClient.get(API_URL, { params: { id }, signal });

    if (trafficSources.length < 1) throw new Error();

    return trafficSources[0];
  });

  const getAllTraffic = createApiCall(async ({ signal }) => {
    const data = await apiClient.get(API_REDIRECT_URL, { signal });

    return data.data.redirectTags;
  });

  const addTraffic = createApiCall(async ({ signal }, trafficValues) => {
    const {
      data: { trafficSource },
    } = await apiClient.post(API_URL, trafficValues, { signal });

    return trafficSource;
  });

  const editTraffic = createApiCall(
    async ({ signal }, trafficId, trafficValues) => {
      const {
        data: { trafficSource },
      } = await apiClient.put(`${API_URL}/${trafficId}`, trafficValues, {
        signal,
      });

      return trafficSource;
    }
  );

  const deleteTraffic = createApiCall(async ({ signal }, id) => {
    await apiClient.delete(`${API_URL}/${id}`, { signal });
  });

  const addTrafficParams = createApiCall(
    async ({ signal }, sourceId, params) => {
      const {
        data: { trafficSource },
      } = await apiClient.post(`${API_URL}/${sourceId}/parameters`, params, {
        signal,
      });

      return trafficSource;
    }
  );

  const editTrafficParams = createApiCall(
    async ({ signal }, sourceId, paramsId, newParams) => {
      const {
        data: { trafficSource },
      } = await apiClient.put(
        `${API_URL}/${sourceId}/parameters/${paramsId}`,
        newParams,
        { signal }
      );

      return trafficSource;
    }
  );

  const deleteTrafficParams = createApiCall(
    async ({ signal }, sourceId, paramId) => {
      await apiClient.delete(`${API_URL}/${sourceId}/parameters/${paramId}`, {
        signal,
      });
    }
  );

  const getRedirectTags = createApiCall(async ({ signal }, sourceId) => {
    const {
      data: { redirectTags },
    } = await apiClient.get(`${API_URL}/${sourceId}/values`, { signal });

    return redirectTags;
  });

  const editRedirectValue = createApiCall(
    async ({ signal }, redirectId, newGeneratedValue) => {
      const {
        data: { redirectTag },
      } = await apiClient.put(
        `${REDIRECT_URL}/${redirectId}`,
        { generatedValue: newGeneratedValue },
        { signal }
      );
      return redirectTag;
    }
  );

  const generateNewRandomValue = createApiCall(
    async ({ signal }, redirectId) => {
      const {
        data: { redirectTag },
      } = await apiClient.put(
        `${REDIRECT_URL}/${redirectId}`,
        { generatedValue: '' },
        { signal }
      );

      return redirectTag;
    }
  );

  const deleteGeneratedValue = createApiCall(async ({ signal }, redirectId) => {
    await apiClient.delete(`${REDIRECT_URL}/${redirectId}`, { signal });
  });

  const getTrafficByIds = createApiCall(async ({ signal }, ids) => {
    if (ids.length < 1) return [];

    const {
      data: { trafficSources },
    } = await apiClient.get(`${API_URL}`, {
      params: {
        id: ids.join(','),
      },
      signal,
    });

    return trafficSources;
  });

  const getCountries = createApiCall(async ({ signal }) => {
    const {
      data: { countries },
    } = await apiClient.get(`/api/country`, {
      signal,
    });

    return countries;
  });

  const getStates = createApiCall(async ({ signal }, id) => {
    const {
      data: { states },
    } = await apiClient.get(`/api/country/${id}/states`, {
      signal,
    });

    return states;
  });

  const getCities = createApiCall(async ({ signal }, countryId, stateId) => {
    const {
      data: { cities },
    } = await apiClient.get(
      `/api/country/${countryId}/states/${stateId}/cities`,
      {
        signal,
      }
    );

    return cities;
  });

  const bulkArchiveTraffic = createApiCall(async ({ signal }, ids, archive) => {
    const data = await apiClient.put(
      `${API_URL}/bulk-archive`,
      { ids, archive },
      { signal }
    );

    return data;
  })

  return {
    getTraffic,
    addTraffic,
    deleteTraffic,
    getSingleTraffic,
    addTrafficParams,
    deleteTrafficParams,
    editTrafficParams,
    editTraffic,
    getRedirectTags,
    editRedirectValue,
    getAllTraffic,
    deleteGeneratedValue,
    generateNewRandomValue,
    getTrafficByIds,
    getCountries,
    getStates,
    getCities,
    bulkArchiveTraffic,
  };
}

export default useTraffic;
