import AddTraffic from '../../AddTraffic';
import EditTraffic from '../../EditTraffic';
import useTraffic from '../../useTraffic';
import DeleteTraffic from '../../DeleteTraffic';
import DynamicToolbar from '../../../../components/DynamicToolbar';
import DeleteIcon from '@mui/icons-material/Delete';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { render } from '@testing-library/react';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArchiveTraffic from '../../ArchiveTraffic';

function TrafficToolbar({
  selectedRow,
  onAddTrafficDone,
  onEditTrafficDone,
  onDeleteTrafficDone,
  rowToDelete,
  rowToArchive,
  currentData,
  onBulkArchiveDone,
  showArchive,
}) {
  const { addTraffic, editTraffic, deleteTraffic, bulkArchiveTraffic } = useTraffic();

  const onAddTraffic = async (trafficValues) => {
    const newTraffic = await addTraffic().call(trafficValues);
    onAddTrafficDone(newTraffic);
  };

  const onEditTraffic = async (trafficValues) => {
    const newTraffic = await editTraffic().call(selectedRow._id, trafficValues);
    onEditTrafficDone(newTraffic);
  };

  const onDeleteTraffic = async (id) => {
    await deleteTraffic().call(id);

    onDeleteTrafficDone(id);
  };

  const onArchiveTraffic = async(initialData, archive) => {
    const traffic = await bulkArchiveTraffic().call(initialData, archive);
    onBulkArchiveDone(traffic.data.updatedTraffic);
  }

  const modalButtons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open, handleClose) => {
        return (
          <AddTraffic
            open={open}
            handleClose={handleClose}
            onSubmit={onAddTraffic}
          />
        );
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      renderIf: Boolean(selectedRow),
      icon: <EditIcon />,
      render: (open, handleClose) => {
        return (
          <EditTraffic
            open={open}
            handleClose={handleClose}
            onSubmit={onEditTraffic}
            initialData={selectedRow}
          />
        );
      },
    },
    {
      name: 'archive',
      label: 'Bulk Archive/Unarchive',
      renderIf: rowToArchive.length > 1,
      icon: <ArchiveIcon />,
      render: (open, handleClose) => {
        return (
          <ArchiveTraffic
            open={open}
            handleClose={handleClose}
            onSubmit={onArchiveTraffic}
            initialData={rowToArchive}
            showArchive={showArchive}
          />
        );
      },
    },
    {
      name: 'delete',
      label: 'Delete',
      renderIf: Boolean(rowToDelete.length > 0),
      icon: <DeleteIcon />,
      render: (open, handleClose) => {
        return (
          <DeleteTraffic
            open={open}
            handleClose={handleClose}
            onSubmit={onDeleteTraffic}
            initialData={rowToDelete}
          />
        );
      },
    },
  ];

  return <DynamicToolbar modalButtons={modalButtons} />;
}

export default TrafficToolbar;
