import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useContext, useEffect, useState } from 'react';
import { OfferGroupContext } from '../context';
import { TrafficSource } from '../../traffic/types';
import { Alert, Box, Chip } from '@mui/material';
import TooltipIconButton from '../../../components/TooltipIconButton';
import { Delete, Edit } from '@mui/icons-material';
import EditGroupTraffic from '../EditGroupTraffic';
import { CustomOfferGroup, OfferGroupTraffic } from '../types';
import useCustomOffer from '../useCustomOffers';
import ConfirmModal from '../../../components/ConfirmModal';
import OfferGroupTrafficSourcesToolbar from '../OfferGroupTrafficSourcesToolbar';
import { showMessage } from '../../../components/utils/showMessage';

type Props = {};

const OfferGroupSources = (props: Props) => {
  const { offerGroup, traffic, setOfferGroup, setLoading, loading } =
    useContext(OfferGroupContext);

  const trafficData = offerGroup?.trafficSources ?? [];

  const [editingRow, setEditingRow] = useState<
    OfferGroupTraffic | null | undefined
  >(null);

  const client = useCustomOffer();

  const map = Object.fromEntries(traffic.map((t) => [t._id, t]));

  const openEditModal = (id: string) => {
    const row = trafficData.find((t) => t.id === id);
    setEditingRow(row);
  };

  const closeEditModal = () => setEditingRow(null);

  const onAddOfferGroupSourcesDone = async (newValue: OfferGroupTraffic) => {
    try {
      const newdata = [...offerGroup!.trafficSources, newValue];

      const newTraffic: CustomOfferGroup = {
        ...offerGroup!,
        trafficSources: newdata,
      };

      await client.updateOfferGroup().call(newTraffic._id, newTraffic);
      showMessage({
        message: 'Traffic source added successfully!',
      });
      setOfferGroup(newTraffic);

      closeEditModal();
    } catch (error: any) {
      showMessage({ message: error.message, severity: 'error' });
    }
  };

  const onEditGroupTraffic = async (newValue: OfferGroupTraffic) => {
    try {
      const newdata = offerGroup!.trafficSources.map((t) => {
        if (t.id === newValue.id) return newValue;
        return t;
      });

      const newTraffic: CustomOfferGroup = {
        ...offerGroup!,
        trafficSources: newdata,
      };

      await client.updateOfferGroup().call(newTraffic._id, newTraffic);
      showMessage({
        message: 'Traffic source updated successfully!',
      });
      setOfferGroup(newTraffic);

      closeEditModal();
    } catch (error: any) {
      showMessage({ message: error.message, severity: 'error' });
    }
  };

  const deleteRow = async (id: string) => {
    setLoading(true);
    try {
      const newrows = offerGroup!.trafficSources.filter((t) => t.id !== id);

      const dto = {
        ...offerGroup!,
        trafficSources: newrows,
      };

      await client.updateOfferGroup().call(dto._id, dto);
      showMessage({ message: 'Traffic source deleted successfully' });
      setOfferGroup(dto);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const cols: GridColDef<OfferGroupTraffic>[] = [
    {
      headerName: 'Detected Used Traffic Sources',
      field: 'name',
      flex: 1,
      valueGetter: (params) => {
        const { id } = params.row;
        return map[id]?.name ?? id;
      },
    },
    {
      headerName: 'Click Id for Report',
      field: 'clickId',
      flex: 1,
      renderCell: ({ row }) => {
        const label = row.clickId || 'None';

        return <Chip label={label} />;
      },
    },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" gap={1}>
            <TooltipIconButton
              title="Edit"
              onClick={() => openEditModal(row.id)}
              icon={<Edit />}
            />
            <ConfirmModal
              iconButton
              title="Delete"
              onConfirm={() => deleteRow(row.id)}
              icon={<Delete />}
            >
              <Alert severity="error">
                Are you sure you want to delete this row?
              </Alert>
            </ConfirmModal>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        columns={cols}
        rows={trafficData}
        getRowId={(r) => r.id}
        disableRowSelectionOnClick={true}
        autoHeight
        loading={loading}
        slots={{
          toolbar: () => (
            <OfferGroupTrafficSourcesToolbar
              onAddOfferGroupSourcesDone={onAddOfferGroupSourcesDone}
            />
          ),
        }}
      />
      {editingRow && (
        <EditGroupTraffic
          trafficData={editingRow}
          handleClose={closeEditModal}
          onSubmit={onEditGroupTraffic}
          trafficSource={map[editingRow.id]}
        />
      )}
    </>
  );
};

export default OfferGroupSources;
