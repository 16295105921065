import React, { useState } from 'react';
import { Range } from 'react-date-range';
import { Box, Button, IconButton, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DateFilter from '../DateFilter';

type Props = {
  onDateFilterSubmit: (range: Range) => void;
  initialDateRange: Range;
};

const RequestTableToolbar: React.FC<Props> = ({
  onDateFilterSubmit,
  initialDateRange,
}) => {
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [openFilter, setOpenFilter] = useState(false);

  const handleDatePickerSubmit = (newRange: Range) => {
    setDateRange(newRange);
    onDateFilterSubmit(newRange);
    setOpenFilter(false);
  };

  return (
    <Box display="column" alignItems="center" pb={2} bgcolor="background.paper">
      <Button
        color="primary"
        variant="outlined"
        startIcon={<FilterAltIcon />}
        onClick={() => setOpenFilter(true)}
        size="small"
      >
        Date Filter
      </Button>

      <DateFilter
        open={openFilter}
        handleClose={() => setOpenFilter(false)}
        onSubmit={handleDatePickerSubmit}
        initialData={dateRange}
      />
    </Box>
  );
};

export default RequestTableToolbar;
