import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Divider,
  Box,
  Autocomplete,
} from '@mui/material';
import useApi from '../../../hooks/useApi';

function EditCronJob({ open, handleClose, onSubmit, initialData }) {
  const [formData, setFormData] = useState(initialData);
  const [trafficSources, setTrafficSources] = useState([]);
  const [loading, setLoading] = useState(false);

  const API_URL = '/api/traffic';

  const { api: apiClient, createApiCall } = useApi();

  const getTrafficSources = createApiCall(async ({ signal }) => {
    const { data } = await apiClient.get(API_URL, { signal });
    return data;
  });

  const init = () => {
    const apiCall = getTrafficSources();

    (async function () {
      try {
        setLoading(true);
        const res = await apiCall.call();
        setTrafficSources(res.trafficSources);
        setLoading(res.trafficSources.length > 0 ? false : true);
      } catch (e) {
      } finally {
        // setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  const selectedTraffic = trafficSources.find(
    (t) => t._id === formData.trafficId
  );

  const selectedTrafficOptions = selectedTraffic
    ? selectedTraffic.parameterOptions?.map((p) => (
        <MenuItem key={p.tag} value={p.tag}>
          {p.tag}
        </MenuItem>
      ))
    : [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAutocompleteChange = (event, value) => {
    setFormData({ ...formData, trafficId: value ? value._id : '' });
  };

  const handleSubmit = () => {
    if (!formData.reportTag || !formData.trafficId || !formData.account) {
      alert('Please fill all the required fields!');
      return;
    }
    onSubmit(formData, handleClose);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)} fullWidth>
      <DialogTitle>Edit Cron Job</DialogTitle>
      <Divider sx={{ mt: 1 }} />
      <DialogContent>
        <Box gap={2} display={'flex'} flexDirection={'column'}>
          <TextField
            name="account"
            label="Account"
            select
            onChange={handleChange}
            value={formData.account}
            fullWidth
          >
            <MenuItem value="sovrn">Sovrn (Sovrn CNX account)</MenuItem>
            <MenuItem value="sean">Sean (Sean@ CNX account)</MenuItem>
          </TextField>
          <TextField
            fullWidth
            label="Report Tag"
            name="reportTag"
            value={formData.reportTag}
            onChange={handleChange}
          />
          <Autocomplete
            options={trafficSources}
            getOptionLabel={(option) => option.name}
            onChange={handleAutocompleteChange}
            value={trafficSources.find((t) => t._id === formData.trafficId) || null}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Traffic Source"
              />
            )}
          />
          <TextField
            name="filterTag"
            label="Prefix"
            select
            disabled={!formData.trafficId}
            value={formData.filterTag}
            onChange={handleChange}
            fullWidth
          >
            {selectedTrafficOptions}
          </TextField>
          <TextField
            fullWidth
            label="Endpoint"
            name="endpoint"
            value={formData.endpoint}
            onChange={handleChange}
            select
          >
            <MenuItem value="xmptrk">XMPTRK</MenuItem>
            <MenuItem value="eagleview">Eagleview</MenuItem>
          </TextField>
        </Box>
      </DialogContent>
      <Divider sx={{ mt: 2 }} />
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCronJob;