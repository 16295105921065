import { useEffect, useState, useMemo, useContext } from 'react';
import { Card, Chip, Box, Typography, Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';

import usePostback from '../usePostback';
import { PostbackContext } from '../context';
import PostbackToolbar from './Toolbar';
import ConfirmModal from '../../../components/ConfirmModal';
import { Download } from '@mui/icons-material';

function PostbackTable() {
  const { getPostbacks, deletePostback } = usePostback();

  const [postbacks, setPostbacks] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const { traffic } = useContext(PostbackContext);

  const onAddPostbackDone = (newPostback) => {
    const postbackArray = [newPostback].flat();
    setPostbacks([...postbacks, ...postbackArray]);
  };

  const onEditPostbackDone = (newPostback) => {
    const newPostbacks = postbacks.map((item) => {
      if (newPostback._id === item._id) return newPostback;
      return item;
    });
    setPostbacks(newPostbacks);
  };

  const onDeletePostback = async (id) => {
    console.log(id);
    try {
      setLoading(true);
      await deletePostback().call(id);
      const newPostbacks = postbacks.filter((item) => item._id !== id);
      setPostbacks(newPostbacks);
    } finally {
      setLoading(false);
    }
  };

  const init = () => {
    const apiCall = getPostbacks();

    (async function () {
      try {
        const postbacks = await apiCall.call();
        setPostbacks(postbacks);
      } catch (e) {
        console.log('Fail fetching postbacks', e);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  const trafficMap = useMemo(() => {
    return traffic.reduce((acc, item) => {
      acc[item._id] = item;
      return acc;
    }, {});
  }, [traffic]);

  const selectedRowData = useMemo(() => {
    return postbacks.find((item) => item._id === selectedRow[0]?._id);
  }, [selectedRow, postbacks]);

  const tableCols = generateTableCols(trafficMap, onDeletePostback);

  const onDownloadTemplate = () => {
    const templateData = [
      ['Generated Value', 'Payout', 'Conversion Date'],
      ['dummyVal', '0.1', 'YYYY-MM-DD']
    ];
  
    const csvContent = templateData.map((row) => row.join(',')).join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'PostbackFiringTemplate.csv';
  
    link.click();
  
    URL.revokeObjectURL(link.href);
  };

  return (
    <Card>
      <Box display="flex" alignItems="center">
        <PostbackToolbar
          setPostbacks={setPostbacks}
          postbacks={postbacks}
          selectedRow={selectedRowData}
          onAddPostbackDone={onAddPostbackDone}
          onEditPostbackDone={onEditPostbackDone}
        />
        <Button startIcon={<Download />} onClick={onDownloadTemplate}>
          Download Template
        </Button>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '1000px',
        }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          rowData={postbacks}
          columnDefs={tableCols}
          getRowId={(params) => params.data._id}
          enableCellTextSelection={true}
          defaultColDef={{
            sortable: true,
            filter: true,
            floatingFilter: true,
            flex: 1,
            wrapText: true,
            autoHeight: true,
            cellStyle: { display: 'flex', alignItems: 'center', padding: '0' },
          }}
          rowSelection="single"
          onSelectionChanged={(event) =>
            setSelectedRow(event.api.getSelectedRows())
          }
          pagination={true}
          paginationPageSize={20}
        />
      </Box>
    </Card>
  );
}

const generateTableCols = (trafficMap, onDelete) => [
  {
    headerName: 'Destination ID',
    field: 'destinationId',
    flex: 1,
    filter: 'agTextColumnFilter',
    checkboxSelection: true,
  },
  {
    headerName: 'Destination Name',
    field: 'destinationName',
    flex: 2,
    filter: 'agTextColumnFilter',
    cellStyle: { justifyContent: 'center' },
  },
  {
    headerName: 'Conditions',
    field: 'conditionRegex',
    flex: 1.5,
    filter: 'agTextColumnFilter',
    valueGetter: ({ data }) => {
      return data.conditionRegex
        .map(({ parameter, value, type }) => {
          const typeLabels = ['', 'equals', 'does not contain', 'contains'];
          if (type === 0 || parameter === '' || value === '') {
            return 'No conditions';
          }
          return `${parameter} ${typeLabels[type]} ${value}`;
        })
        .join(', ');
    },
    cellRenderer: ({ data }) => {
      const { conditionRegex } = data;

      return conditionRegex.map(({ parameter, value, type }, index) => {
        const typeLabels = ['', 'equals', 'does not contain', 'contains'];

        if (type === 0 || parameter === '' || value === '') {
          return <Chip label="No conditions" />;
        }

        const label = `${parameter} ${typeLabels[type]} ${value}`;

        return <Chip key={index} label={label} sx={{ mr: 1 }} />;
      });
    },
  },
  {
    headerName: 'Destination URL',
    field: 'destinationUrl',
    flex: 3,
    filter: 'agTextColumnFilter',
    cellRenderer: ({ data }) => {
      const { destinationUrl } = data;
      return (
        <div>
          {destinationUrl.map((destination, index) => (
            <Box sx={{ mb: 0.5, mt: 0.5 }} key={index}>
              {index + 1}. {destination}
            </Box>
          ))}
        </div>
      );
    },
  },
  {
    headerName: 'Traffic Source',
    field: 'trafficSourceId',
    flex: 2,
    filter: 'agTextColumnFilter',
    valueGetter: ({ data }) => {
      return data.trafficSourceId
        .map((traffic) => trafficMap[traffic]?.name || 'No traffic source')
        .join(', ');
    },
    cellRenderer: ({ data }) => {
      const { trafficSourceId } = data;

      if (trafficSourceId.length < 1) {
        return (
          <>
            <Typography sx={{ mt: 0.7, mr: 1 }}>1. </Typography>
            <Chip label="No traffic source" color="warning" />
          </>
        );
      }

      return (
        <div>
          {trafficSourceId.map((traffic, index) => {
            const label = trafficMap[traffic]?.name;

            if (!label) return null;

            return (
              <Link
                key={index}
                to={`/traffic/${traffic}`}
                style={{
                  display: 'flex',
                  width: '100%',
                  textDecoration: 'none',
                }}
              >
                <Typography sx={{ mt: 0.7, mr: 1 }}>{index + 1}. </Typography>
                <Chip
                  label={label}
                  color="info"
                  sx={{
                    cursor: 'pointer',
                    marginRight: '8px',
                    marginBottom: '8px',
                  }}
                />
              </Link>
            );
          })}
        </div>
      );
    },
  },
];

export default PostbackTable;
