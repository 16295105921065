import React, { useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
  Divider,
  MenuItem,
  Button,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SimpleModal from '../../../components/SimpleModal';
import LoadingButton from '../../../components/LoadingButton';
import GroupFieldSelector from '../GroupFieldSelector';
import { showMessage } from '../../../components/utils/showMessage';
import DeleteIcon from '@mui/icons-material/Delete';
import * as nanoid from 'nanoid';
import { Add } from '@mui/icons-material';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (updatedOffer: any) => any;
  offerData: any;
};

type Condition = {
  id: string;
  type: string;
  value: string;
};

const conditionTypes = [
  { label: 'Minimum Length', value: 'minLength' },
  { label: 'Maximum Length', value: 'maxLength' },
  { label: 'Contains', value: 'contains' },
  { label: 'Starts With', value: 'startsWith' },
  { label: 'Ends With', value: 'endsWith' },
];

const EditCustomOfferGroup = ({
  open,
  handleClose,
  onSubmit,
  offerData,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    label: offerData.label ?? '',
    key: offerData.key ?? '',
  });
  const [conditions, setConditions] = useState<Condition[]>(
    offerData.conditions || []
  );

  const fieldRef = useRef<any>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleAddCondition = () => {
    setConditions((prev) => [
      ...prev,
      { id: nanoid.nanoid(), type: '', value: '' },
    ]);
  };

  const handleConditionChange = (
    id: string,
    field: keyof Condition,
    value: string
  ) => {
    setConditions((prev) =>
      prev.map((cond) => (cond.id === id ? { ...cond, [field]: value } : cond))
    );
  };

  const handleDeleteCondition = (id: string) => {
    setConditions((prev) => prev.filter((cond) => cond.id !== id));
  };

  const handleSubmit = async () => {
    if (!fieldRef.current) return;
    setLoading(true);

    try {
      const fields = fieldRef.current.getFields();

      await onSubmit({
        ...values,
        fields,
        conditions,
      });
      showMessage({ message: 'Record updated successfully!.' });
    } catch (error: any) {
      showMessage({
        message: error.any || 'Something went wrong.',
        severity: 'error',
      });
    }
    setLoading(false);
    handleClose();
  };

  const selectedTypes = conditions.map((cond) => cond.type);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        Edit Custom Offer Group
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          size="small"
          label="Label"
          name="label"
          onChange={onChange}
          value={values.label}
          fullWidth
        />
        <TextField
          size="small"
          label="Key"
          name="key"
          onChange={onChange}
          value={values.key}
          fullWidth
          sx={{
            marginY: 2,
          }}
        />
        <GroupFieldSelector
          label="Fields"
          initialValues={offerData.fields}
          ref={fieldRef}
        />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h6">Conditions</Typography>
          <Box>
            <IconButton onClick={handleAddCondition}>
              <Add />
            </IconButton>
          </Box>
        </Box>
        <Divider
          sx={{
            mb: 2,
          }}
        ></Divider>
        {conditions.map((condition) => (
          <div
            key={condition.id}
            style={{ display: 'flex', gap: '16px', marginBottom: '8px' }}
          >
            <TextField
              size="small"
              select
              label="Condition Type"
              value={condition.type}
              onChange={(e) => {
                const newType = e.target.value as Condition['type'];
                handleConditionChange(condition.id, 'type', newType);
                handleConditionChange(condition.id, 'value', '');
              }}
              fullWidth
            >
              {conditionTypes.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={
                    selectedTypes.includes(option.value) &&
                    condition.type !== option.value
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              size="small"
              label="Value"
              value={condition.value}
              onChange={(e) => {
                let value = e.target.value;
                if (
                  condition.type === 'minLength' ||
                  condition.type === 'maxLength'
                ) {
                  value = value.replace(/[^0-9]/g, '');

                  if (value === '') {
                    handleConditionChange(condition.id, 'value', '');
                  } else {
                    handleConditionChange(condition.id, 'value', value);
                  }
                } else {
                  handleConditionChange(condition.id, 'value', value);
                }
              }}
              type="text"
              fullWidth
              inputProps={{
                inputMode: 'numeric',
                onWheel: (e) => e.currentTarget.blur(),
              }}
            />
            <IconButton onClick={() => handleDeleteCondition(condition.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          loading={loading}
          color="primary"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditCustomOfferGroup;
