import useApi from '../../hooks/useApi';

const API_URL = '/api/postback';
const FIRING_URL = 'api/p';

export default function usePostback() {
  const { api: apiClient, createApiCall } = useApi();

  const getPostbacks = createApiCall(async ({ signal }) => {
    const {
      data: { postbacks },
    } = await apiClient.get(API_URL, { signal });

    return postbacks;
  });

  const addPostback = createApiCall(async ({ signal }, newPostback) => {
    const {
      data: { postback },
    } = await apiClient.post(API_URL, newPostback, { signal });

    return postback;
  });

  const deletePostback = createApiCall(async ({ signal }, postbackId) => {
    await apiClient.delete(`${API_URL}/${postbackId}`, { signal });
  });

  const editPostback = createApiCall(
    async ({ signal }, postbackId, newPostback) => {
      const {
        data: { postback },
      } = await apiClient.put(`${API_URL}/${postbackId}`, newPostback, {
        signal,
      });

      return postback;
    }
  );

  const selectedFiring = createApiCall(async ({ signal }, requestData) => {
    const data  = await apiClient.post(
      `${API_URL}/postback-csvfiring`, requestData, { signal }
    );

    return data;
  });

  return { getPostbacks, addPostback, deletePostback, editPostback, selectedFiring };
}
