import React, { useEffect, useState } from 'react';

import moment from 'moment';

import 'react-data-grid/lib/styles.css';

import DataGrid, { Column, SelectColumn, TreeDataGrid } from 'react-data-grid';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import RequestTableToolbar from '../Toolbar';
import { Range } from 'react-date-range';
import useClicks from '../../useClicks';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';

interface Row {
  _id: number;
  domainDetails?: { domainName: string };
  offerDetails?: { name: string };
  trafficDetails?: { name: string };
  createdAt: Date;
}

function rowKeyGetter(row: Row) {
  return row._id;
}

const options = [
  'domainDetails',
  'trafficDetails',
  'offerDetails',
  'createdAt',
] as const;

const optionLabels: Record<string, string> = {
  offerDetails: 'Offer Details',
  trafficDetails: 'Traffic Details',
  domainDetails: 'Domain Details',
  createdAt: 'Created At',
};

function DataGridReact() {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [totalRowCount, setTotalRowCount] = useState(0);

  const client = useClicks();

  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<number> => new Set()
  );
  const [selectedOptions, setSelectedOptions] = useState<readonly string[]>([]);
  const [expandedGroupIds, setExpandedGroupIds] = useState(
    (): ReadonlySet<unknown> =>
      new Set<unknown>([
        'United States of America',
        'United States of America__2015',
      ])
  );

  function toggleOption(option: string, enabled: boolean) {
    const index = selectedOptions.indexOf(option);
    if (enabled) {
      if (index === -1) {
        setSelectedOptions((options) => [...options, option]);
      }
    } else if (index !== -1) {
      setSelectedOptions((options) => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        return newOptions;
      });
    }
    setExpandedGroupIds(new Set());
  }

  const handleDateFilterSubmit = (newDateRange: Range) => {
    setDateRange(newDateRange);
    setLoading(true);
    setPaginationModel({ ...paginationModel, page: 0 });
    fetchData(
      0,
      paginationModel.pageSize,
      newDateRange.startDate,
      newDateRange.endDate,
      sortModel
    );
  };

  const fetchData = async (
    page: number,
    pageSize: number,
    startDate: Date | undefined,
    endDate: Date | undefined,
    sortModel: GridSortModel
  ) => {
    const api = client.getClicks();
    setLoading(true);
    try {
      const startDateFinal = startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD');
      const endDateFinal = endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD');

      const sortColumn = sortModel[0]?.field ?? 'createdAt';
      const sortDirection = sortModel[0]?.sort ?? 'desc';

      const res = await api.call(
        startDateFinal,
        endDateFinal,
        page,
        pageSize,
        sortColumn,
        sortDirection
      );

      setData(res.clicks.result);
      setTotalRowCount(res.clicks.totalCount);
    } catch (error) {
      console.log('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(
      0,
      paginationModel.pageSize,
      dateRange.startDate,
      dateRange.endDate,
      sortModel
    );
  }, []);

  const columns: readonly Column<any>[] = [
    {
      key: 'createdAtDateTime',
      name: 'Date Time',
      width: 200,
      renderCell: ({ row }) => {
        return row._id && row.createdAt
          ? new Date(row.createdAt).toISOString().slice(0, 16).replace('T', ' ')
          : '';
      },
    },
    {
      key: '_id',
      name: 'ID',
      width: 220,
      renderCell: ({ row }) => (row._id ? row._id : ''),
    },
    {
      key: 'createdAt',
      name: 'Date',
      width: 150,
      renderCell: ({ row }) => {
        return row._id && row.createdAt
          ? new Date(row.createdAt).toISOString().split('T')[0]
          : '';
      },
    },
    {
      key: 'request.ip',
      name: 'IP',
      width: 150,
      renderCell: ({ row }) => (row._id ? row.request?.ip ?? 'N/A' : ''),
    },
    {
      key: 'offerDetails',
      name: 'Offer',
      width: 250,
      renderCell: ({ row }) => (row._id ? row.offerDetails?.name ?? 'N/A' : ''),
    },
    {
      key: 'trafficDetails',
      name: 'Traffic Source',
      width: 250,
      renderCell: ({ row }) =>
        row._id ? row.trafficDetails?.name ?? 'N/A' : '',
    },
    {
      key: 'domainDetails',
      name: 'Domain',
      width: 250,
      renderCell: ({ row }) =>
        row._id ? row.domainDetails?.domainName ?? 'N/A' : '',
    },
    {
      key: 'request.userAgent',
      name: 'User Agent',
      width: 300,
      renderCell: ({ row }) => (row._id ? row.request?.userAgent ?? 'N/A' : ''),
    },
    {
      key: 'headers.geoip_city_name',
      name: 'Location',
      width: 250,
      renderCell: ({ row }) => {
        if (!row._id || !row.headers) return '';
        const city = row.headers.geoip_city_name ?? '';
        const state = row.headers.geoip_state_code ?? '';
        const country = row.headers.geoip_country_code ?? '';
        return [city, state, country].filter(Boolean).join(', ') || 'N/A';
      },
    },
    {
      key: 'isBlocked',
      name: 'Is Blocked',
      width: 150,
      renderCell: ({ row }) =>
        row._id ? (
          <Chip
            label={row.isBlocked ? 'Blocked' : 'Not Blocked'}
            color={row.isBlocked ? 'error' : 'success'}
          />
        ) : (
          ''
        ),
    },
    {
      key: 'blockingRules',
      name: 'Block Reason',
      width: 250,
      renderCell: ({ row }) => {
        if (!row._id) return '';
        const reasons = formatBlockingReason(row.blockingRules);

        return (
          <Box
            display="flex"
            gap={1}
            flexWrap="wrap"
            sx={{
              padding: '4px 0',
              alignItems: 'center',
              minHeight: '32px',
            }}
          >
            {reasons.map((reason: string, index: number) => (
              <Chip
                key={index}
                variant="outlined"
                color={reason === 'N/A' ? 'default' : 'error'}
                label={reason}
                sx={{
                  height: '24px',
                  fontSize: '0.75rem',
                  lineHeight: '20px',
                }}
              />
            ))}
          </Box>
        );
      },
    },
  ];

  const formatBlockingReason = (blockingRules: any[]) => {
    if (!blockingRules || blockingRules.length === 0) return ['N/A'];

    const reasons: string[] = [];

    blockingRules.forEach((ruleObj: any) => {
      if (ruleObj.rule?.blockLocations) {
        const blockLocation = ruleObj.rule.blockLocations;
        blockLocation.locations.forEach((loc: any) => {
          const type = loc.type ?? 'Unknown';
          const country = loc.country?.name ?? 'Unknown Country';
          const state =
            typeof loc.state === 'string' && loc.state === 'all'
              ? 'All States'
              : loc.state?.name ?? 'Unknown State';
          const city =
            typeof loc.city === 'string' && loc.city === 'all'
              ? 'All Cities'
              : loc.city?.name ?? 'Unknown City';

          reasons.push(`${type.toUpperCase()} - ${country}, ${state}, ${city}`);
        });
      } else {
        const ruleKeys = ruleObj.rule ? Object.keys(ruleObj.rule) : [];
        ruleKeys.forEach((key) => {
          reasons.push(capitalizeWords(key.replace(/([A-Z])/g, ' $1').trim()));
        });
      }
    });

    return reasons.length > 0 ? reasons : ['N/A'];
  };

  const capitalizeWords = (str: string) => {
    return str
      .split(' ')
      .map((word) => word.toUpperCase())
      .join(' ');
  };

  const handlePageChange = (direction: 'next' | 'prev') => {
    setPaginationModel((prev) => {
      const newPage =
        direction === 'next'
          ? Math.min(
              prev.page + 1,
              Math.ceil(totalRowCount / prev.pageSize) - 1
            )
          : Math.max(prev.page - 1, 0);

      fetchData(
        newPage,
        prev.pageSize,
        dateRange.startDate,
        dateRange.endDate,
        sortModel
      );
      return { ...prev, page: newPage };
    });
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    const newPageSize = event.target.value as number;

    setPaginationModel((prev) => {
      const newModel = {
        ...prev,
        pageSize: newPageSize,
        page: 0,
      };

      fetchData(
        newModel.page,
        newModel.pageSize,
        dateRange.startDate,
        dateRange.endDate,
        sortModel
      );

      return newModel;
    });
  };

  return (
    <Box mt={4} height={'90vh'}>
      <Box display={'flex'} gap={1} alignItems={'center'} mb={2}>
        <Chip
          variant="outlined"
          color="primary"
          label={`Starts From: ${moment(dateRange.startDate).format(
            'YYYY-MM-DD'
          )}`}
        ></Chip>
        <Chip
          variant="outlined"
          color="primary"
          label={`Ends To: ${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
        ></Chip>
      </Box>
      <Paper sx={{ p: 2, height: '100%' }}>
        <RequestTableToolbar
          onDateFilterSubmit={handleDateFilterSubmit}
          initialDateRange={dateRange}
        />
        <Typography>Group by columns:</Typography>
        <Box gap={2} display={'flex'} my={1}>
          {options.map((option) => (
            <label key={option} style={{ marginRight: '10px' }}>
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={(event) => toggleOption(option, event.target.checked)}
              />{' '}
              {optionLabels[option]}
            </label>
          ))}
        </Box>
        <Divider></Divider>
        <Box
          sx={{
            height: '75%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TreeDataGrid
              columns={columns}
              rows={data}
              rowKeyGetter={rowKeyGetter}
              selectedRows={selectedRows}
              onSelectedRowsChange={setSelectedRows}
              groupBy={selectedOptions}
              rowGrouper={rowGrouper}
              expandedGroupIds={expandedGroupIds}
              onExpandedGroupIdsChange={setExpandedGroupIds}
              style={{ height: '100%', width: '100%' }}
            />
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Typography>
              <small>
                Page {paginationModel.page + 1} of{' '}
                {Math.ceil(totalRowCount / paginationModel.pageSize)} | Total
                Rows: {totalRowCount}
              </small>
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              ml={2}
              gap={1}
              width={'200px'}
            >
              <Select
                value={paginationModel.pageSize}
                onChange={handlePageSizeChange}
                size="small"
                displayEmpty
                sx={{ minWidth: 70 }}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={500}>500</MenuItem>
                <MenuItem value={1000}>1000</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={() => handlePageChange('prev')}
              disabled={paginationModel.page === 0}
              sx={{ mr: 1 }}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={() => handlePageChange('next')}
              disabled={
                paginationModel.page + 1 >=
                Math.ceil(totalRowCount / paginationModel.pageSize)
              }
            >
              Next
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

function rowGrouper(rows: readonly Row[], columnKey: string) {
  const groups: Record<string, Row[]> = {};

  rows.forEach((row) => {
    if (!row._id && columnKey === 'createdAt') {
      return;
    }

    let value: any;

    if (columnKey === 'trafficDetails') {
      value = row.trafficDetails?.name ?? 'N/A';
    } else if (columnKey === 'createdAt') {
      value = row.createdAt
        ? new Date(row.createdAt).toISOString().split('T')[0]
        : 'N/A';
    } else if (columnKey === 'offerDetails') {
      value = row.offerDetails?.name ?? 'N/A';
    } else if (columnKey === 'domainDetails') {
      value = row.domainDetails?.domainName ?? 'N/A';
    } else if (columnKey.includes('.')) {
      value = columnKey
        .split('.')
        .reduce((acc, key) => acc && acc[key], row as any);
    } else {
      value = row[columnKey as keyof Row];
    }

    const groupKey = value ?? 'N/A';

    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }

    groups[groupKey].push(row);
  });

  const formattedGroups: Record<string, Row[]> = {};
  Object.keys(groups).forEach((key) => {
    const count = groups[key].filter((row) => row._id).length;
    formattedGroups[`${key} (${count})`] = groups[key];
  });

  return formattedGroups;
}

export default DataGridReact;
