import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import moment from 'moment';

const DatePickerDialog = ({ onSubmit, utcMode = true, onDateRangeChange }) => {
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().subtract(7, 'days').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [maxDate, setMaxDate] = useState(new Date());

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const handleChange = (newValue) => {
    const { selection } = newValue;
    setDateRange([selection]);
    onDateRangeChange([selection]);
  };

  const handleSubmit = () => {
    setOpen(false);

    const { startDate, endDate } = dateRange[0];

    let finalStart = startDate;
    let finalEnd = endDate;

    if (utcMode) {
      finalStart = moment(finalStart).utc().toDate();
      finalEnd = moment(finalEnd).utc().toDate();
    }

    onSubmit({ startDate: finalStart, endDate: finalEnd });
  };

  useEffect(() => {
    const utcDate = moment.utc(new Date());
    const newMaxDate = utcDate.toDate();
    setMaxDate(newMaxDate);
  }, []);

  return (
    <>
      <Button variant="text" onClick={openModal} startIcon={<FilterListIcon />}>
        Date filter
      </Button>
      <Dialog open={open} onClose={closeModal} maxWidth="md">
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }}>
          <DateRangePicker
            onChange={handleChange}
            ranges={dateRange}
            maxDate={maxDate}
            months={1}
            direction="horizontal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>OK</Button>
          <Button onClick={closeModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DatePickerDialog;