import useApi from '../../hooks/useApi';

export default function useCronSettings() {
  const API_URL = '/api/cron-jobs-sovrn';
  const API_URL_TRAFFIC = '/api/traffic';

  const { api: apiClient, createApiCall } = useApi();

  const getCronSettings = createApiCall(async ({ signal }) => {
    const { data } = await apiClient.get(API_URL, { signal });
    return data;
  });

  const addCronSettings = createApiCall(async ({ signal }, newSettings) => {
    const { data } = await apiClient.post(API_URL, newSettings, { signal });
    return data;
  });

  const editCronSettings = createApiCall(
    async ({ signal }, settingId, newSettings) => {
      const { data } = await apiClient.put(
        `${API_URL}/${settingId}`,
        newSettings,
        { signal }
      );

      return data;
    }
  );

  const deleteCronSettings = createApiCall(async ({ signal }, settingId) => {
    await apiClient.delete(`${API_URL}/${settingId}`, { signal });
  });

  const deleteCronSettingsBulk = createApiCall(async ({ signal }, ids) => {
    await apiClient.delete(`${API_URL}/bulk-delete`, {
      data: { ids },
      signal
    });
  });

  const getTrafficSources = createApiCall(async ({ signal }) => {
    const { data } = await apiClient.get(API_URL_TRAFFIC, { signal });
    return data;
  });

  return {
    getCronSettings,
    addCronSettings,
    editCronSettings,
    deleteCronSettings,
    deleteCronSettingsBulk,
    getTrafficSources,
  };
}
