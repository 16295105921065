import React, { memo, useMemo } from 'react';
import DynamicToolbar from '../../../components/DynamicToolbar';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCronJob from '../AddSettings/index';
import EditCronJob from '../EditSettings/index';
import DeleteCronJob from '../DeleteSettings/index';
import useCronSettings from '../service';

function CronJobToolbar({
  selectedRow,
  onAddDone,
  onEditDone,
  onDeleteDone,
  rowToDelete,
  multipleSelected,
}) {

  const { addCronSettings, editCronSettings, deleteCronSettings, deleteCronSettingsBulk } = useCronSettings();

  const onAddCronJob = async (newCronJob, handleClose) => {
    const setting = await addCronSettings().call(newCronJob);
    onAddDone(setting);
    handleClose(true);
  };

  const onEditCronJob = async (newCronJob) => {
    const settings = await editCronSettings().call(selectedRow._id, newCronJob);
    onEditDone(settings);
  };

  const onDeleteCronJob = async (id, handleClose) => {
    await deleteCronSettings().call(id);
    onDeleteDone(id);
    handleClose(true);
  };

  const onDeleteCronJobsBulk = async (ids, handleClose) => {
    await deleteCronSettingsBulk().call(ids);
    onDeleteDone(ids);
    handleClose(true);
  };

  const modalButtons = useMemo(() => {
    return [
      {
        name: 'add',
        label: 'Add',
        renderIf: true,
        icon: <AddIcon />,
        render: (open, handleClose) => (
          <AddCronJob
            open={open}
            handleClose={handleClose}
            onSubmit={onAddCronJob}
          />
        ),
      },
      {
        name: 'edit',
        label: 'Edit',
        renderIf: Boolean(selectedRow) && !multipleSelected,
        icon: <EditIcon />,
        render: (open, handleClose) => (
          <EditCronJob
            open={open}
            handleClose={handleClose}
            onSubmit={onEditCronJob}
            initialData={selectedRow}
          />
        ),
      },
      {
        name: 'delete',
        label: 'Delete',
        renderIf: Boolean(rowToDelete?.length > 0),
        icon: <DeleteIcon />,
        render: (open, handleClose) => (
          <DeleteCronJob
            open={open}
            handleClose={handleClose}
            onSubmit={multipleSelected ? onDeleteCronJobsBulk : onDeleteCronJob}
            initialData={rowToDelete}
          />
        ),
      },
    ];
  }, [selectedRow, rowToDelete, multipleSelected]);

  return <DynamicToolbar modalButtons={modalButtons} />;
}

export default memo(CronJobToolbar);