import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box } from '@mui/material';

export default function DeletePostback({
  open,
  initialData,
  handleClose,
  onSubmit,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Postback</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to delete this Postback?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => onSubmit(initialData, handleClose)}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}