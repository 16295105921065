import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default function ArchiveTraffic({
  open,
  initialData,
  handleClose,
  onSubmit,
  showArchive,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{showArchive ? 'Unarchive Offers' : 'Archive Offers'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to {showArchive ? 'Unarchive' : 'Archive'} the selected offers?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {showArchive ? (
          <Button
            variant="contained"
            onClick={() => onSubmit(initialData, false, handleClose(true))}
            color="secondary"
          >
            Unarchive
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => onSubmit(initialData, true, handleClose(true))}
            color="primary"
          >
            Archive
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}