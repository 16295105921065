import moment from 'moment';

const toYMDFormat = (date) => {
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};

const applyTimeZoneOffset = (date, timeZone) => {
  let d1 = new Date(date.toDate());

  d1 = new Date(toYMDFormat(d1));
  d1.setUTCHours(0, 0, 0, 0);
  const d2 = moment(d1);

  switch (timeZone) {
    case 'UTC':
      return d2.clone().utc();
    case 'EST':
      return d2.clone().add(4, 'hours');
    case 'PST':
      return d2.clone().add(7, 'hours');
    default:
      return d2;
  }
};

const applyReverseTimeZoneOffset = (date, timeZone) => {
  switch (timeZone) {
    case 'UTC':
      return date.clone().utc();
    case 'EST':
      return date.clone().subtract(4, 'hours');
    case 'PST':
      return date.clone().subtract(7, 'hours');
    default:
      return date;
  }
};

export const convertDateFromUTC = (utcDate, targetTimeZone) => {
  return applyReverseTimeZoneOffset(utcDate, targetTimeZone);
};

export const convertDateRangeToUTC = (dateRange, selectedTimeZone) => {
  const { startDate, endDate } = dateRange;

  const convertedStartDate = applyTimeZoneOffset(startDate, selectedTimeZone);
  const convertedEndDate = applyTimeZoneOffset(endDate, selectedTimeZone);

  return { convertedStartDate, convertedEndDate };
};

export const finalDateRange = async (dates, timezone) => {
  const { startDate, endDate } = dates;
  const momentStartDate = startDate instanceof Date ? moment(startDate) : null;
  const momentEndDate = endDate instanceof Date ? moment(endDate) : null;

  if (momentStartDate && momentEndDate) {
    const { convertedStartDate, convertedEndDate } = convertDateRangeToUTC(
      { startDate: momentStartDate, endDate: momentEndDate },
      timezone
    );

    const finalStartDate = convertedStartDate.toDate();
    const finalEndDate = convertedEndDate.toDate();

    return { finalStartDate, finalEndDate };
  }

  return null;
};

export default { convertDateRangeToUTC, finalDateRange };