import { useEffect, useState, useRef } from 'react';
import { Card, Typography, IconButton, Box, Chip, CircularProgress } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import RefreshIcon from '@mui/icons-material/Refresh';
import moment from 'moment';
import { format } from 'date-fns';
import useLogs from '../useLogs';
import SimpleToolbar from '../../../components/SimpleToolbar';
import DatePickerDialog from '../LogsDateFilter';
import { finalDateRange } from '../service.js';

function LogsTable() {
  const { getLogsByDate } = useLogs();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().subtract(7, 'days').toDate(),
      endDate: new Date(),
      key: 'selection',
    }
  ]);
  const [finalLogs, setfinalLogs] = useState([]);
  const isInitialMount = useRef(true);

  const init = () => {
    logsByDate(dateRange);
  };

  const logsByDate = (dateRange) => {
    setLoading(true);
    const apiCall = getLogsByDate();
  
    const startDate = dateRange[0].startDate;
    const endDate = dateRange[0].endDate;

    (async function () {
      try {
        const logsByDate = await apiCall.call(startDate, endDate);
        setfinalLogs(logsByDate);
      } catch (e) {
        console.log('Fail fetching logs', e);
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      init();
    }
  }, []);

  const handleDateRangeChange = async (newDateRange) => {
    try {
      const utcDateRange = await Promise.all(
        newDateRange.map(async (range) => {
          const { finalStartDate, finalEndDate } = await finalDateRange(
            { startDate: range.startDate, endDate: range.endDate },
            'UTC'
          );
          return {
            ...range,
            startDate: finalStartDate,
            endDate: finalEndDate,
          };
        })
      );
  
      setDateRange(utcDateRange);
    } catch (error) {
      console.error('Error converting date range to UTC:', error);
    }
  };
  
  const onSubmit = () => {
    setLoading(true);
    logsByDate(dateRange);
  };
  
  const tableCols = generateTableCols();

  const exportOptions = {
    timestamp: {
      label: 'Timestamp',
      formatter: (value) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm') : '';
      },
    },
    level: 'Level',
    meta: {
      label: 'Parameters',
      formatter: (value) => {
        if (!value) return 'No parameters used';
        const metaKeys = Object.keys(value);
        return metaKeys
          .filter((key) => key !== 'url')
          .map((key) => `${key}: ${value[key]}`)
          .join(', ');
      },
    },
    'meta.url': {
      label: 'Called URL',
      formatter: (value, row) => {
        const meta = row.meta;
        if (!meta || !meta.url) return 'No URL Available';
        const mainUrl = window.location.origin;
        return `${mainUrl}${meta.url}`;
      },
    },
    message: 'Message',
  };

  const exportFileName = `postback-logs${format(dateRange[0].startDate, 'yyyy-MM-dd')}_${format(dateRange[0].endDate, 'yyyy-MM-dd')}.csv`;

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2} mb={1}>
        <Typography variant="h6">Postback Logs</Typography>
        <IconButton size="small" color="primary" onClick={init}>
          <RefreshIcon />
        </IconButton>
      </Box>
      <Card>
        <Box display="flex" alignItems="center" gap={2}>
          <SimpleToolbar
            allowExport
            rawExportData={finalLogs}
            exportFileName={exportFileName}
            exportOptions={exportOptions}
          />
          <DatePickerDialog 
          onSubmit={onSubmit}
          onDateRangeChange={handleDateRangeChange}
           />
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '1000px',
          }}
          className="ag-theme-alpine"
        >
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <AgGridReact
              rowData={finalLogs}
              columnDefs={tableCols}
              defaultColDef={{
                flex: 1,
                sortable: true,
                filter: true,
                wrapText: true,
                autoHeight: true,
                floatingFilter: true,
                cellStyle: {
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0',
                },
              }}
              pagination={true}
              paginationPageSize={20}
              enableCellTextSelection={true}
            />
          )}
        </Box>
      </Card>
    </Box>
  );
}

const generateTableCols = () => [
  {
    headerName: 'Timestamp',
    field: 'timestamp',
    flex: 1,
    cellStyle: { justifyContent: 'center' },
    valueGetter: (params) => params.data.timestamp,
    cellRenderer: (params) => {
      const timestamp = params.value;
      return timestamp
        ? moment(timestamp).format('YYYY-MM-DD HH:mm [GMT]Z')
        : '';
    },
    comparator: (valueA, valueB) => {
      const dateA = new Date(valueA);
      const dateB = new Date(valueB);
      return dateA - dateB;
    },
  },
  {
    headerName: 'Level',
    field: 'level',
    flex: 1,
    cellStyle: { justifyContent: 'center' },
    cellRenderer: (params) => {
      const level = params.data.level;
      if (level === 'info') {
        return <Chip label="Info" color="info" />;
      } else if (level === 'success') {
        return <Chip label="Success" color="success" />;
      }
      return <Chip label="Error" color="error" />;
    },
  },
  {
    headerName: 'Parameters',
    field: 'meta',
    flex: 1,
    valueGetter: (params) => {
      const meta = params.data.meta;
      if (!meta) return 'No parameters used';

      const metaKeys = Object.keys(meta);
      return metaKeys
        .filter((key) => key !== 'url')
        .map((key) => `${key}: ${meta[key]}`)
        .join(', ');
    },
    cellRenderer: (params) => {
      const meta = params.data.meta;
      if (!meta) return <div>No parameters used</div>;

      const metaKeys = Object.keys(meta);
      return (
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {metaKeys.map(
            (key) =>
              key !== 'url' && (
                <div key={key}>
                  <strong>{key}:</strong> {meta[key]}
                </div>
              )
          )}
        </div>
      );
    },
  },
  {
    headerName: 'Called URL',
    field: 'meta.url',
    flex: 2,
    valueGetter: (params) => {
      const meta = params.data.meta;
      if (!meta || !meta.url) return 'No URL Available';

      const mainUrl = window.location.origin;
      return `${mainUrl}${meta.url}`;
    },
    cellRenderer: (params) => {
      const meta = params.data.meta;
      if (!meta || !meta.url)
        return <Typography variant="caption">No URL Available</Typography>;

      const mainUrl = window.location.origin;
      return (
        <Typography
          variant="caption"
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >{`${mainUrl}${meta.url}`}</Typography>
      );
    },
  },
  {
    headerName: 'Message',
    field: 'message',
    flex: 3,
  },
];

export default LogsTable;